'use client';

import gaEvent from '@/utils/ga';
import { useParams, useRouter } from 'next/navigation';

export default function ButtonStartFree({ t }) {
  const params = useParams();
  const { lang } = params;
  const router = useRouter();
  const handleGaEvent = () => {
    router.push(`/${lang}/signup`);
    gaEvent(`start_for_free_click`, {
      event_category: 'Click',
      value: 0,
    });
  };

  return (
    <button
      type="button"
      onClick={handleGaEvent}
      className="w-fit min-w-[300px] rounded-full bg-btn-main-gradient-to-r p-5 text-white transition-all duration-200 ease-out hover:bg-btn-main-gradient-to-r-hover hover:text-black lg:px-10 lg:py-5"
    >
      <p className="typo-heading-sm lg:typo-heading-md">{t.homeStartButton}</p>
      <p className="typo-paragraph-xs lg:typo-paragraph-sm">
        {t.homeButtonSlogan}
      </p>
    </button>
  );
}
