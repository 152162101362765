import { useEffect, useState, FC } from 'react';

interface BaseSwitchProps {
  large?: boolean;
  value?: boolean;
  disabled?: boolean;
  label?: string;
  ariaLabel?: string;
  onChange?: (value: boolean) => void;
}

/**
 * `BaseSwitch` 是一個基本的開關元件。
 *
 * @component
 * @param {boolean} [props.large=true] - 決定開關的大小。如果為 `true`，則開關為大型；否則為小型。
 * @param {boolean} [props.value=false] - 開關的初始狀態。如果為 `true`，則開關為開啟狀態；否則為關閉狀態。
 * @param {boolean} [props.disabled=false] - 決定開關是否被禁用。如果為 `true`，則開關為禁用狀態；否則為啟用狀態。
 * @param {string} [props.label=''] - 開關的標籤文字。
 * @param {function} [props.onChange=()=>{}] - 當開關狀態變化時觸發的回調函數。該函數接收一個參數，表示開關的新狀態。
 * @param {string} [props.ariaLabel=''] - 開關的 aria-label 屬性。
 * @returns {React.Element} 一個 `BaseSwitch` 開關元件的實例。
 */
const BaseSwitch: FC<BaseSwitchProps> = ({
  large = true,
  value = false,
  disabled = false,
  label = '',
  ariaLabel = '',
  onChange = () => {},
}) => {
  const [on, setOn] = useState(false);

  const clickSwitch = () => {
    if (!disabled) {
      setOn(!on);
      onChange(!on);
    }
  };

  useEffect(() => {
    setOn(value);
  }, [value]);

  return (
    <div
      className={`flex select-none flex-row items-center ${large ? 'gap-2' : 'gap-1'} `}
    >
      <button
        type="button"
        onClick={clickSwitch}
        disabled={disabled}
        aria-pressed={on}
        aria-label={ariaLabel}
        className={`flex flex-row gap-2 rounded-[20px] border-0 transition-all duration-300 ease-in-out hover:shadow-[0_0_0_3px_#A9D8FF] focus:shadow-[0_0_0_3px_#A9D8FF] ${
          large ? 'w-[56px] p-1' : 'w-[32px] p-[2px]'
        } ${
          on ? `bg-primary-900 ${disabled ? 'opacity-40' : ''}` : 'bg-gray-200'
        }`}
      >
        <div
          className={`rounded-full bg-white shadow-[2px_0px_5px_0px_#0000001F] transition-all duration-300 ease-in-out disabled:bg-gray-300 ${large ? 'size-6' : 'size-4'} ${on && large ? 'ml-6' : on && !large ? 'ml-3' : 'ml-0'} `}
        />
      </button>
      {label && (
        <span
          className={`${disabled ? 'opacity-40' : ''} text-gray-900 ${large ? 'typo-paragraph-sm' : 'typo-paragraph-xs'}`}
        >
          {label}
        </span>
      )}
    </div>
  );
};

export default BaseSwitch;
